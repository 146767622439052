import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyB3woyZVlv96JtztvNUiFxn9RVHrUshEOE",
    authDomain: "react-netflix-clone-780.firebaseapp.com",
    projectId: "react-netflix-clone-780",
    storageBucket: "react-netflix-clone-780.appspot.com",
    messagingSenderId: "549597043241",
    appId: "1:549597043241:web:e2cbcd5f0100d37d2c9a38"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth };
export default db;